<template>
    <div>
        <el-table stripe v-loading="loading" :data="dataList" >
            <el-table-column label="商户编号" prop="merchantNo" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="上游商户编号" prop="outMerchantNo" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="渠道" prop="channelNo" min-width="120" :show-overflow-tooltip="true" >
                <template slot-scope="scope">
                    {{ scope.row.channelNo | channelFM }}
                </template>
            </el-table-column>
            <el-table-column label="商户简称" prop="lsMerchantName" min-width="160" :show-overflow-tooltip="true"/>
            <el-table-column label="激活状态" prop="activeStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.activeStatus==0?'primary':'success'"  
                    plain size="mini" class="button-status" v-if="scope.row.activeStatus">{{scope.row.activeStatus | activeStatusFM}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="所属代理商" prop="agentName" min-width="160" :show-overflow-tooltip="true"/>
            <el-table-column label="所属代理商编号" prop="agentNo" min-width="140" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.agentNo}}<span @click="showAgentLevel(scope.row)" style="color:rgb(2,167,240); cursor: pointer;">(代理层级)</span>
                </template>
            </el-table-column>
            <el-table-column label="所属机构" prop="orgNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="商户状态" prop="merchantStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.merchantStatus=='CHECK_FAIL'?'danger':scope.row.merchantStatus=='CHECK_SUCC'?'success':'primary'"  
                    plain size="mini" class="button-status" v-if="scope.row.merchantStatus">{{scope.row.merchantStatus | newMerchantStatusFM}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="注册时间" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="80" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text"
                               @click="handleDetial(scope.row)"
                               v-permission="'MERCHANT_INFO_QUERY'">
                               <i class="el-icon-edit-outline"></i>
                               详情
                    </el-button>
                    <!-- <el-button size="mini" type="text"
                               @click="handleDelete(scope.row)"
                               v-permission="'MERCHANT_INFO_DELETE'">注销</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <Detial :data="baseInfo" :on-fresh="showDetail" @on-close="handlerDetailOnclose"></Detial>
        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
        <el-dialog 
        :visible.sync="agentLevelVisible"
        width="1100px">
            <template>
                <el-table stripe :data="agentRankInfo" style="width: 100%">
                    <el-table-column prop="userLevel" label="代理商等级" />
                    <el-table-column prop="userNo" label="代理商编号" />
                    <el-table-column prop="userName" label="代理商名称" />
                </el-table>
                <el-row><br></el-row>
                <el-table stripe :data="orgRankInfo" style="width: 100%">
                    <el-table-column prop="userLevel" label="招商中心等级" />
                    <el-table-column prop="userNo" label="招商中心编号" />
                    <el-table-column prop="userName" label="招商中心名称" />
                </el-table>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="agentLevelVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {MerchantApi} from '@/api';
    import Detial from "./Detial";
    export default {
        name: "ToolBar",
        components: {Detial},
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showDetail: false,
                isAdd: false,
                dialogVisible: false,
                baseInfo: {},
                agentLevelShow: false,
                agentLevelVisible: false,
                agentRankInfo: [],
                orgRankInfo:[]
            }
        },
        mounted() {
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await MerchantApi.getMerchantlist(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data || [];
            },
            handlerDetailOnclose() {
                this.showDetail = false;
            },
            handleStatusChange(row) {
                let text = row.status === "DISABLE" ? "禁用" : "启用";
                this.$confirm("是否确定"+text+"?","警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await BusinessApi.merchantInfo.edit(row.id, {
                        state: row.state
                    });
                    if (result.success) {
                        this.getList();
                    }
                }).catch(() => this.getList());
            },
            async handleUpdate(row) {
                this.showCustomForm = true;
                this.selectRow = row;
                this.isAdd = false;
            },
            async handleDetial(row){
                let result = await MerchantApi.getMerchantDetail(row.merchantNo)
                this.showDetail = true
                this.baseInfo = result.success? result.data : {}
            },
            handleDelete(row){
                this.$confirm("是否确定注销商户编号为"+row.merchantNo+"的数据项",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await MerchantApi.merchantInfo.remove(row.merchantNo);
                    if (result.success) {
                        this.Message.success("删除成功");
                        this.getList();
                    }
                });
            },
            async showAgentLevel(row){
                let result = await MerchantApi.queryAgentRankInfo(row.agentNo)
                this.agentRankInfo = result.data.agentRankInfo
                this.orgRankInfo = result.data.orgRankInfo
                this.agentLevelVisible = true
                console.log(result)
            }
        }
    };
</script>

<style scoped>

</style>
